
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import administration from '@/components/administration.vue'; // @ is an alias to /src
import administrationheader from '@/components/administrationheader.vue'; // @ is an alias to /src
import BuiltinService from '@/services/buitin-service';
import Loader from '@/components/loader.vue'; // @ is an alias to /src

@Component({
  components: {
    Header,
    Loader,
    administration,
    administrationheader
  },
})
export default class BranchBuiltin extends Vue {
  public fields = [{ key: 'title', sortable: true }, { key: 'actions', label: 'Actions' }];
  error_mesg = null;
  loading = true;
  public items = [];
  filterOn = [];
  filter = null;
  private branch: any = { title: "" };

  public currentPage = 1;
  get rows() { return this.items.length; }
  retrieve() {
    this.loading = true;
    BuiltinService.getbranch().then((response) => {
      this.items = response.data;
      this.loading = false;
    })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  handleSubmit() {

    this.create();
  }
  create() {
    this.loading = true;
    this.error_mesg = null;
    if (this.branch['id'] != null) {
      BuiltinService.putbranch(this.branch['id'], this.branch)
        .then((response) => {
          if (response) {
            this.retrieve();
            this.branch = { title: "" }
            this.loading = false;
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    }
    else {
      BuiltinService.postbranch(this.branch)
        .then((response) => {
          if (response) {
            this.retrieve();
            this.branch = { title: "" }
            this.loading = false;
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    }
  }
  onFiltered(filteredItems: any) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    // this.rows = filteredItems.length
    // this.currentPage = 1
  }
  delete(id: any) {
    BuiltinService.deletebranch(id)
      .then((response) => {
        if (response) {
          this.retrieve();
          this.branch = { id: null, title: "" }
          this.loading = false;
        }
      })
      .catch((e) => {
        let eror = e.response.data;
        let r = eror[Object.keys(eror)[0]];
        this.error_mesg = r;
        this.loading = false;
      });
  }

  info(row: any) {
    this.branch['id'] = row['id'];
    this.branch['title'] = row['title'];
    this.branch['user'] = row['user'];
  }
  removeinfo(id: any) {
    this.delete(id);
  }
  mounted() {
    this.retrieve();
  }
}
