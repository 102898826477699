
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import administration from '@/components/administration.vue'; // @ is an alias to /src
import Loader from '@/components/loader.vue'; // @ is an alias to /src
import administrationheader from '@/components/administrationheader.vue'; // @ is an alias to /src
import BuiltinService from '@/services/buitin-service';

@Component({
  components: {
    Header,
    Loader,
    administration,
    administrationheader
  },
})
export default class NationalityBuiltin extends Vue {
  public fields = [{ key: 'title', sortable: true }, { key: 'actions', label: 'Actions' }];
  error_mesg = null;
  loading = true;
  filter = null;
  public items = [];
  private nationality: any = { title: "" };

  filterOn = [];
  public currentPage = 1;
  get rows() { return this.items.length; }
  retrieve() {
    this.loading = false;
    BuiltinService.getnationality().then((response) => {
      this.items = response.data;
      this.loading = false;
    })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  handleSubmit() {

    this.create();
  }
  create() {
    this.loading = true;
    BuiltinService.postnationality(this.nationality)
      .then((response) => {
        if (response) {
          this.retrieve();
          this.nationality = { title: "" }
          this.loading = false;
        }
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  onFiltered(filteredItems: any) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    // this.rows = filteredItems.length
    // this.currentPage = 1
  }
  mounted() {
    this.retrieve();
  }
}
