
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Loader from '@/components/loader.vue'; // @ is an alias to /src
import administration from '@/components/administration.vue'; // @ is an alias to /src
import administrationHeader from '@/components/administrationheader.vue'; // @ is an alias to /src
import BuiltinService from '@/services/buitin-service';
@Component({
  components: {
    Header,
    administration,
    administrationHeader,
    Loader
  },
})
export default class AssestsBuiltin extends Vue {
  filter = null;
  public fields = [{ key: 'title', sortable: true }, { key: 'actions', label: 'Actions' }];
  filterOn = [];
  loading = true;
  error_mesg: any = null;
  public items = [];
  private assets: any = { id: null, title: "", type: "" };
  public type_data = [
    { value: '1', text: 'Land' },
    { value: '2', text: 'Buildings' },
    { value: '3', text: 'Leasehold Improvements' },
    { value: '4', text: 'Computers and computer-related items' },
    { value: '5', text: 'Software' },
    { value: '6', text: 'Office Equipment' },
    { value: '7', text: 'Air-Conditioning Units' },
    { value: '8', text: 'Furniture & Fixtures' },
    { value: '9', text: 'Heavy Machinery' },
    { value: '10', text: 'Equipment & Machines' },
    { value: '11', text: 'Tools' },
    { value: '12', text: 'Vehicles' }
  ]

  public currentPage = 1;
  get rows() { return this.items.length; }

  retrieve() {
    this.loading = true;
    BuiltinService.getasset().then((response) => {
      this.items = response.data;
      this.loading = false;
    })
      .catch((e) => {
        console.log(e);
      });
  }

  handleSubmit() {

    this.create();
  }

  create() {
    this.loading = true;
    this.error_mesg = null;
    if (this.assets['id'] != null) {
      BuiltinService.putasset(this.assets['id'], this.assets)
        .then((response) => {
          if (response) {
            this.retrieve();
            this.assets = { id: null, title: "", type: "" }
            this.loading = false;
          }
        })
        .catch((e) => {
          let eror = e.response.data;
          let r = eror[Object.keys(eror)[0]];
          this.error_mesg = r;
          this.loading = false;
        });
    } else {
      BuiltinService.postasset(this.assets)
        .then((response) => {
          if (response) {
            this.retrieve();
            this.assets = { id: null, title: "", type: "" }
            this.loading = false;
          }
        })
        .catch((e) => {
          let eror = e.response.data;
          let r = eror[Object.keys(eror)[0]];
          this.error_mesg = r;
          this.loading = false;
        });
    }
  }

  delete(id: any) {
    BuiltinService.deleteasset(id)
      .then((response) => {
        if (response) {
          this.retrieve();
          this.assets = { id: null, title: "", type: "" }
          this.loading = false;
        }
      })
      .catch((e) => {
        let eror = e.response.data;
        let r = eror[Object.keys(eror)[0]];
        this.error_mesg = r;
        this.loading = false;
      });
  }

  info(row: any) {
    this.assets['id'] = row['id'];
    this.assets['title'] = row['title'];
    this.assets['type'] = row['type'];
    this.assets['user'] = row['user'];
  }
  removeinfo(id: any) {
    this.delete(id);
  }

  onFiltered(filteredItems: any) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    // this.rows = filteredItems.length
    // this.currentPage = 1
  }

  mounted() {
    this.retrieve()
  }
}
