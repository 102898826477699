
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import administrationSidebar from '@/components/administration.vue'; // @ is an alias to /src
import administrationHeader from '@/components/administrationheader.vue'; // @ is an alias to /src
import Assets from "./assets/assets.vue";
import BloodGroup from './blood-group/blood-group.vue';
import Branch from "./branch/branch.vue";
import Department from "./department/department.vue";
import Designation from "./designations/designations.vue";
import EducationDetails from "./education-details/education-details.vue";
import EmployementTypes from "./employment-types/employment-types.vue";
import Expats from "./expats/expats.vue";
import ImportAdmin from "./import/import.vue";
import Nationality from "./nationality/nationality.vue";
import SubBranch from "./sub-branch/sub-branch.vue";
@Component({
  components: {
    Header,
    administrationSidebar,
    administrationHeader,
    Assets,
    BloodGroup,
    Branch,
    Department,
    Designation,
    EducationDetails,
    EmployementTypes,
    Expats,
    ImportAdmin,
    Nationality,
    SubBranch
  },
})
export default class Administration extends Vue {
  activeState: string = "assets";
  handleActive(item: any) {
    // console.log(item);
    this.activeState = item;
  }
  public items = [{ code: '', title: '', credit: '', project: '', reference: '', description: '' }];
  account_options = [];
  branch_options = [];
  project_options = [];
  trans: any = {};

  addRow() {
    this.items.push({ code: '', title: '', credit: '', project: '', reference: '', description: '' })
  }
  save() {
    let obj: any = {};
    obj['date'] = new Date();
    console.log(this.items);
  }
  mounted() {
    // this.getaccounts();
  }
}
